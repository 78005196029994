import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { graphql } from 'gatsby'
import React, { useMemo } from 'react'
import { HeroImage } from '../../../components/EngageRings/HeroImage'
import { Layout } from '../../../components/Layout'
import { Link } from '../../../components/Link'
import { RingGallery, convertEngageRing } from '../../../components/RingGallery'
import { SEO } from '../../../components/SEO'
import { Txt } from '../../../components/Txt'
import type { EngageRingsByMaterialQuery } from '../../../../types/graphql-types'
import type { PageProps } from 'gatsby'

export const Head = () => (
    <SEO
        title="安い婚約指輪・激安エンゲージリングを安心な素材から探せる｜ミスプラチナ"
        description="格安の婚約指輪を素材から決める！卸売価格だから実現できる高品質で低価格なエンゲージリングならミスプラチナでご購入いただけます。"
        page="engagering/material"
    />
)

const IndexPage: React.FC<PageProps<EngageRingsByMaterialQuery>> = ({
    data,
}) => {
    const muiTheme = useTheme()
    const platinumRings = useMemo(() => {
        return data.platinumRings.edges.map((edge) => {
            return convertEngageRing(edge.node)
        })
    }, [data])
    const whitegoldRings = useMemo(() => {
        return data.k18GoldRings.edges.map((edge) => {
            return convertEngageRing(edge.node)
        })
    }, [data])

    return (
        <Layout
            breadcrumbs={[
                { name: '安い婚約指輪・エンゲージリング', path: 'engagering/' },
                { name: '素材から探す' },
            ]}
        >
            <Grid container spacing={0} component="main">
                <Grid item xs={12}>
                    <HeroImage />
                </Grid>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <TitleWrapper>
                        <H1>
                            <Txt size="l">素材</Txt>
                            <Txt size="s">から婚約指輪を探す</Txt>
                        </H1>
                        <Description theme={muiTheme}>
                            <Txt size="xs">
                                ミスプラチナでは素材にこだわったプラチナリング、ホワイトゴールドリングを取り揃えております。
                            </Txt>
                        </Description>
                    </TitleWrapper>
                </Grid>
                <Grid item xs={12}>
                    <CategorizeRings>
                        <H2>
                            <Txt size="l">プラチナ</Txt>
                        </H2>
                        <Description2>
                            <Txt size="s">
                                耐久性や素材の希少性が高く、海外人気のブランドでも定番のハードプラチナを使用。
                                白金と呼ばれることから白系の特徴的な色合いが魅力的です。
                            </Txt>
                        </Description2>
                        <RingGallery rings={platinumRings} />
                        <MoreLinkWrapper>
                            <Link to="/engagering/material/platinum/">
                                プラチナの婚約指輪をもっと見る
                            </Link>
                        </MoreLinkWrapper>
                    </CategorizeRings>
                    <CategorizeRings>
                        <H2>
                            <Txt size="l">ホワイトゴールド</Txt>
                        </H2>
                        <RingGallery rings={whitegoldRings} />
                        <MoreLinkWrapper>
                            <Link to="/engagering/material/whitegold/">
                                ホワイトゴールドの婚約指輪をもっと見る
                            </Link>
                        </MoreLinkWrapper>
                    </CategorizeRings>
                </Grid>
            </Grid>
        </Layout>
    )
}

export const query = graphql`
    query EngageRingsByMaterial {
        platinumRings: allEngageringsJson(
            filter: { material: { eq: "platinum" } }
            limit: 5
            sort: { fields: price, order: ASC }
        ) {
            edges {
                node {
                    code
                    name
                    material
                    price
                    images
                }
            }
        }
        k18GoldRings: allEngageringsJson(
            filter: { material: { eq: "whitegold" } }
            limit: 5
            sort: { fields: price, order: ASC }
        ) {
            edges {
                node {
                    code
                    name
                    material
                    price
                    images
                }
            }
        }
    }
`

const TitleWrapper = styled('div')({
    padding: '20px',
})
const H1 = styled('h1')({
    margin: '0 0 8px',
    lineHeight: 1,
})
const H2 = styled('h2')({
    margin: '0 0 8px',
    lineHeight: 1,
    padding: '10px',
    borderBottom: '1px solid #aaaaaa',
})
const Description = styled('p')(({ theme }) => ({
    margin: '0 0 20px',
    color: theme.palette.text.secondary,
}))
const Description2 = styled('p')({
    margin: '10px 10px 16px',
})
const CategorizeRings = styled('section')({
    margin: '10px 10px 28px',
})
const MoreLinkWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'end',
    margin: '20px 10px',
})

export default IndexPage
